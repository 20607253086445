// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  name: 'qa-na',
  production: false,
  region: 'NA',
  appUrl: 'https://emissions-check.qa.zonarsystems.net',

  apiBase: {
    url: 'https://emissions-check-api.qa.zonarsystems.net/api/v1'
  },

  geocoderApi: {
    url: 'https://zonar-nonprod-qa.apigee.net'
  },

  coreEntityApiBase: {
    url: 'https://api-qa.zonarsystems.net/core/v1'
  },

  //  TODO: Setup your application's authentication variables: see
  //  https://gitlab.com/ZonarSystems/tools/pattern-library/-/tree/master/projects/zonar-ui-auth
  // for instructions on how to do that.
  authEnv: 'qa',

  auth: {
    clientID: 'kF6uJLvGOPCwyhQRbKm5TwFKFrL3AzhG',
    domain: 'qa-login.zonarsystems.net',
    audience: 'http://apiqa.zonarsystems.net/',
    // These come from the Core APIs as your application is built.
    applicationId: '9158f96b-da1b-421d-a942-1f2d36bda46a',
    defaultZonarRole: '9892a567-a3a4-4908-b7ce-7d79bacdb261',
    useRefreshTokens: true
  },

  pendo: {
    url: 'https://cdn.pendo.io/agent/static/'
  },

  devCycle: {
    clientId: 'dvc_client_8ed08f85_7f19_48a1_984c_d9664ace50dd_aa48b52'
  },

  googleTagManager: {
    id: 'GTM-PM9R42P',
    auth: '',
    environment: ''
  },

  datadog: {
    applicationId: 'b98eb354-3b80-421c-a916-54dda63afa57',
    clientToken: 'pub6a4c810b136693403837854ef96b11f6',
    site: 'us5.datadoghq.com',
    tagEnvironment: 'qa'
  },

  i18n: {
    supportedLanguages: ['en-US', 'de-DE', 'en-GB', 'it-IT', 'fr-FR', 'es-ES'],
    defaultLanguage: 'en-US'
  },

  userPreferenceApiBase: {
    url: 'https://api-qa.zonarsystems.net/alert/v1'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
